import { BusinessModel } from 'enums/businessModel';
import { Locale } from 'enums/locale';
import { RefObject } from 'react';
import { SearchState } from 'types/search.type';
import { NonFunctionStatePropertyNames } from 'types/state.type';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

type SetterFunction<T> = (val: T) => void;

type NullableDate = Date | null;

interface SearchStore {
  allSearchData: SearchState[];
  searchLocale: Locale | null;
  setAllSearchData: SetterFunction<SearchState[]>;
  setSearchLocale: SetterFunction<Locale | null>;

  showLocationDropdown: boolean;
  showNumberOfPeopleDropdown: boolean;
  showDateDropdown: boolean;
  searchValue: string;
  filteredSearchResults: SearchState[];
  numberOfPeople: number;
  startTime: NullableDate;
  endTime: NullableDate;
  businessModel: BusinessModel;
  isFlexSearch: boolean;
  locationInputRef: RefObject<HTMLInputElement> | null;
  startHour: number | null;
  endHour: number | null;
  selectedLocation: SearchState | null;
  setSelectedLocation: SetterFunction<SearchState | null>;
  setStartHour: SetterFunction<number | null>;
  setEndHour: SetterFunction<number | null>;
  setShowLocationDropdown: SetterFunction<boolean>;
  setShowNumberOfPeopleDropdown: SetterFunction<boolean>;
  setShowDateDropdown: SetterFunction<boolean>;
  setSearchValue: SetterFunction<string>;
  setFilteredSearchResults: SetterFunction<SearchState[]>;
  setNumberOfPeople: SetterFunction<number>;
  setStartTime: SetterFunction<NullableDate>;
  setEndTime: SetterFunction<NullableDate>;
  setBusinessModel: SetterFunction<BusinessModel>;
  setIsFlexSearch: SetterFunction<boolean>;
  setLocationInputRef: SetterFunction<RefObject<HTMLInputElement> | null>;
}

export const useSearchState = create<SearchStore>()(
  devtools((set) => {
    const setter =
      (key: NonFunctionStatePropertyNames<SearchStore>) =>
      (val: SearchStore[typeof key]) =>
        set((state) => ({ ...state, [key]: val }));
    return {
      allSearchData: [] as SearchState[],
      searchLocale: null as Locale | null,
      setAllSearchData: setter('allSearchData'),
      setSearchLocale: setter('searchLocale'),
      showLocationDropdown: false,
      showNumberOfPeopleDropdown: false,
      showDateDropdown: false,
      searchValue: '',
      filteredSearchResults: [],
      numberOfPeople: 0,
      startTime: null,
      endTime: null,
      businessModel: BusinessModel.HOURLY,
      isFlexSearch: true,
      locationInputRef: null,
      startHour: null,
      endHour: null,
      selectedLocation: null,
      setSelectedLocation: setter('selectedLocation'),
      setStartHour: setter('startHour'),
      setEndHour: setter('endHour'),
      setLocationInputRef: setter('locationInputRef'),
      setBusinessModel: setter('businessModel'),
      setIsFlexSearch: setter('isFlexSearch'),
      setShowLocationDropdown: setter('showLocationDropdown'),
      setShowNumberOfPeopleDropdown: setter('showNumberOfPeopleDropdown'),
      setShowDateDropdown: setter('showDateDropdown'),
      setSearchValue: setter('searchValue'),
      setFilteredSearchResults: setter('filteredSearchResults'),
      setNumberOfPeople: setter('numberOfPeople'),
      setStartTime: setter('startTime'),
      setEndTime: setter('endTime'),
    };
  })
);

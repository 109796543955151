import BButton from 'components/common/BButton';
import Dropdown from 'components/common/Dropdown';
import DropdownClickableItem from 'components/common/DropdownClickableItem';
import BCustomSelect from 'components/Form/BCustomSelect';
import BSwitch from 'components/Form/BSwitch';
import {
  DEFAULT_END_TIME,
  DEFAULT_HOUR_ADDITION,
  DEFAULT_START_TIME,
} from 'constants/searchDefaultHours';
import { BusinessModel } from 'enums/businessModel';
import { useBreakpoint } from 'hooks/useBreakpoints';
import { useHourGenerator } from 'hooks/useHourGenerator';
import { useLocale } from 'hooks/useLocale';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import { Calendar, CalendarTileProperties } from 'react-calendar';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import { dateService } from 'services/DateService';
import { useSearchState } from 'states/useSearchState';
import { SearchHourOption } from 'types/search.type';
import { cn } from 'utils/cn';
import { SearchMobileCard } from './SearchMobileCard';
import { tr, enUS } from 'date-fns/locale';
import { Locale } from 'enums/locale';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

interface SearchDateSelectorProps {
  from: 'homepage' | 'navbar';
  selectedValue: string;
}

export const SearchDateSelector = ({
  from,
  selectedValue,
}: SearchDateSelectorProps) => {
  const { t } = useTranslation('navbar');
  const {
    showDateDropdown,
    setShowDateDropdown,
    startTime,
    setStartTime,
    setEndTime,
    isFlexSearch,
    setIsFlexSearch,
    setStartHour,
    setEndHour,
    startHour,
    endHour,
    endTime,
    setShowNumberOfPeopleDropdown,
    businessModel,
  } = useSearchState();
  const { isMobile } = useBreakpoint();
  const { locale } = useLocale();
  const dayjs = dateService.getDayjs();
  const today = new Date();

  const close = () => {
    setShowDateDropdown(false);
  };

  const updateDate = (val: Date) => {
    const houredStartDateVal = new Date(val);
    const houredEndDateVal = new Date(val);

    setStartTime(houredStartDateVal);
    setEndTime(houredEndDateVal);
    setStartHour(null);
    setEndHour(null);
  };

  const isSameDay = startTime && dateService.isSameDate(startTime, today);

  const startHours: SearchHourOption<number>[] = useHourGenerator(
    isSameDay ? today.getHours() + 1 : 0,
    24,
    { shouldContinueFromNextDay: false }
  );

  const endHours: SearchHourOption<number>[] = useHourGenerator(
    startHour !== null ? startHour + 1 : 12,
    12,
    { shouldStartFromNextDay: startHour !== null ? startHour >= 23 : false }
  );

  const disabledDates = (val: CalendarTileProperties) => {
    if (val.view !== 'month') {
      return dateService.isPastThenCurrentMonth(val.date);
    }
    const yesterday = dayjs(today).subtract(1, 'day').valueOf();
    const isTodayAndDateSame = dateService.isSameDate(val.date, today);
    return (
      val.date.getTime() < yesterday ||
      (isTodayAndDateSame && new Date().getHours() >= 23)
    );
  };

  const resetDates = () => {
    setStartTime(null);
    setEndTime(null);
    setStartHour(null);
    setEndHour(null);
    setIsFlexSearch(true);
  };

  type mobileStateType = 'date' | 'startTime' | 'endTime' | 'done';
  const [mobileState, setMobileState] = useState<mobileStateType>('date');

  const nextMobileState = () => {
    if (
      mobileState === 'date' &&
      businessModel === BusinessModel.DAILY_WEEKLY
    ) {
      setShowDateDropdown(false);
      setShowNumberOfPeopleDropdown(true);
    }
    if (mobileState === 'date') {
      if (startTime !== null) setMobileState('startTime');
      else {
        setShowNumberOfPeopleDropdown(true);
        setShowDateDropdown(false);
      }
    }
    if (mobileState === 'startTime') {
      setMobileState('endTime');
    }
    if (mobileState === 'endTime') {
      setMobileState('done');
    }
    if (mobileState === 'done') {
      setShowDateDropdown(false);
      setShowNumberOfPeopleDropdown(true);
    }
  };

  useEffect(() => {
    if (mobileState === 'endTime' && startHour === null) {
      const sh =
        startHours.find((h) => h.value === DEFAULT_START_TIME) || startHours[0];
      setDateAndHour(startTime!, sh, setStartTime, setStartHour);
    }
    if (mobileState === 'done' && endHour === null) {
      setEndHour(
        startHour! >= DEFAULT_END_TIME
          ? (startHour! + DEFAULT_HOUR_ADDITION) % 24
          : DEFAULT_END_TIME
      );
      const eh =
        startHour! < DEFAULT_END_TIME &&
        DEFAULT_END_TIME - (startHour || 0) < 12
          ? endHours.find((h) => h.value === DEFAULT_END_TIME)
          : endHours[DEFAULT_HOUR_ADDITION - 1];
      setDateAndHour(endTime!, eh!, setEndTime, setEndHour);
    }
  }, [mobileState]);

  useEffect(() => {
    if (showDateDropdown === false) {
      setMobileState('date');
    }
  }, [showDateDropdown]);

  const resetDatesMobile = () => {
    resetDates();
    setMobileState('date');
  };

  const handleMobileStateClick = (state: mobileStateType) => {
    if (state === 'date') {
      setMobileState('date');
      setStartHour(null);
      setEndHour(null);
    }
    if (state === 'startTime') {
      setMobileState('startTime');
      setEndHour(null);
    }
    if (state === 'endTime') {
      setMobileState('endTime');
    }
  };

  if (isMobile) {
    const dateSectionWrapperClass =
      'flex items-center justify-between rounded-lg border p-3';
    return (
      <SearchMobileCard
        wrapperClass={cn({
          'flex-[1_1_auto] rounded-b-none': showDateDropdown,
          'mx-0':
            showDateDropdown && businessModel === BusinessModel.DAILY_WEEKLY,
          'pb-1.5':
            showDateDropdown && businessModel === BusinessModel.DAILY_WEEKLY,
        })}
        titleClass={cn({
          'ml-5':
            showDateDropdown && businessModel === BusinessModel.DAILY_WEEKLY,
        })}
        selectedValue={selectedValue}
        show={showDateDropdown}
        setShow={setShowDateDropdown}
        title={t('search.date.mobile.title')}
        iconType="date"
      >
        <div className="flex flex-col justify-between gap-4">
          {businessModel === BusinessModel.DAILY_WEEKLY ? (
            <div className="flex justify-center">
              <DWSelector />
            </div>
          ) : (
            <>
              <div className="b-datepicker -no-mobile !static">
                {mobileState === 'date' ? (
                  <div className="p-2">
                    <Calendar
                      className="mx-auto"
                      value={startTime}
                      onChange={updateDate}
                      locale={locale}
                      tileDisabled={disabledDates}
                      next2Label={null}
                      prev2Label={null}
                    />
                  </div>
                ) : (
                  <div
                    className={dateSectionWrapperClass}
                    onClick={() => handleMobileStateClick('date')}
                    role="presentation"
                  >
                    <div>{t('search.date.mobile.selectedDate')}</div>
                    <div>{dateService.getReadableDate(startTime!)}</div>
                  </div>
                )}
              </div>
              {mobileState === 'done' || mobileState === 'endTime' ? (
                <div
                  className={dateSectionWrapperClass}
                  onClick={() => handleMobileStateClick('startTime')}
                  role="presentation"
                >
                  <div>{t('search.date.startHour')}</div>
                  <div className="flex items-center gap-2">
                    {startHours.find((h) => h.value === startHour)?.icon}
                    {(startHour + '').padStart(2, '0') + ':00'}
                  </div>
                </div>
              ) : mobileState === 'startTime' ? (
                <HourSection
                  hours={startHours}
                  type="start"
                  from={from}
                  nextMobileState={nextMobileState}
                />
              ) : null}
              {mobileState === 'done' ? (
                <div
                  className={dateSectionWrapperClass}
                  onClick={() => handleMobileStateClick('endTime')}
                  role="presentation"
                >
                  <div>{t('search.date.endHour')}</div>
                  <div className="flex items-center gap-2">
                    {endHours.find((h) => h.value === endHour)?.icon}
                    {(endHour + '').padStart(2, '0') + ':00'}
                  </div>
                </div>
              ) : mobileState === 'endTime' ? (
                <HourSection
                  hours={endHours}
                  type="end"
                  from={from}
                  nextMobileState={nextMobileState}
                />
              ) : null}
              {mobileState === 'done' ? (
                <div className={dateSectionWrapperClass}>
                  <BSwitch
                    value={isFlexSearch}
                    labelClasses="ml-4"
                    onChange={(val) => setIsFlexSearch(val)}
                    label={t('search.date.flexSearchLabel')}
                  />
                </div>
              ) : null}
              <div id={`date-dropdown-${from}`} />
            </>
          )}
          <div
            className={cn(
              'px-10" absolute bottom-0 -ml-10 flex w-full justify-between border-t bg-white p-2',
              {
                '-ml-4': businessModel === BusinessModel.DAILY_WEEKLY,
              }
            )}
          >
            <BButton
              priority="text"
              variant="secondary"
              onClick={resetDatesMobile}
            >
              {t('search.date.clear')}
            </BButton>
            <BButton variant="secondary" onClick={nextMobileState}>
              {t('search.date.mobile.next')}
            </BButton>
          </div>
        </div>
      </SearchMobileCard>
    );
  }

  return (
    <>
      <Dropdown
        wrapperClass="mt-2 w-full text-base text-left overflow-y-auto overscroll-contain hidden md:block"
        show={showDateDropdown}
        onClose={close}
      >
        {businessModel === BusinessModel.HOURLY ? (
          <div className="b-datepicker !static flex">
            <div className="p-2">
              <Calendar
                minDetail="year"
                value={startTime}
                onChange={updateDate}
                locale={locale}
                tileDisabled={disabledDates}
                next2Label={null}
                prev2Label={null}
              />
            </div>
            <div className="mx-auto flex w-203 flex-col items-center justify-around">
              <HourSection hours={startHours} type="start" from={from} />
              <HourSection hours={endHours} type="end" from={from} />
            </div>
          </div>
        ) : (
          <div className="flex w-full justify-center">
            <DWSelector />
          </div>
        )}
        <div className="flex items-center justify-between px-2 pt-1 pb-2">
          <BSwitch
            value={isFlexSearch}
            labelClasses="text-info-600"
            onChange={(val) => setIsFlexSearch(val)}
            label={t('search.date.flexSearchLabel')}
          />
          <BButton variant="secondary" priority="text" onClick={resetDates}>
            {t('search.date.clear')}
          </BButton>
        </div>
      </Dropdown>
      <div id={`date-dropdown-${from}`} />
    </>
  );
};

const HourSection = ({
  hours,
  type,
  from,
  nextMobileState,
}: {
  hours: SearchHourOption<number>[];
  type: 'start' | 'end';
  from: 'navbar' | 'homepage';
  nextMobileState?: () => void;
}) => {
  const { t } = useTranslation('navbar');
  const {
    startHour,
    endHour,
    setStartHour,
    setEndHour,
    startTime,
    endTime,
    setStartTime,
    setEndTime,
    showDateDropdown,
  } = useSearchState();
  const [show, setShow] = useState<boolean>(false);

  const time = type === 'start' ? startHour : endHour;
  const setTime = type === 'start' ? setStartTime : setEndTime;
  const setHour = type === 'start' ? setStartHour : setEndHour;
  const wrapperId = `hour-dropdown-${type}`;

  const isDisabled = type === 'end' ? startHour === null : startTime === null;

  const onClick = (hour: SearchHourOption<number>) => {
    if (type === 'start' && startTime !== null) {
      setDateAndHour(startTime, hour, setTime, setHour);

      if (
        endTime !== null &&
        endHour !== null
        // &&
        // dateService.isSameDate(startTime, endTime) &&
        // endHour < hour.value
      ) {
        setEndHour(null);
        setEndTime(null);
      }
    }
    if (type === 'end' && startTime !== null) {
      setDateAndHour(
        endTime !== null ? endTime : startTime,
        hour,
        setTime,
        setHour,
        startTime
      );
    }
    setShow(false);
    nextMobileState?.();
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && from === 'homepage') {
      const wrapper = document.getElementById(wrapperId);
      const intersectionObserver = new IntersectionObserver((entries) => {
        entries[0].isIntersecting && setShow(false);
      });
      intersectionObserver.observe(wrapper!);
    }
  }, []);

  useEffect(() => {
    if (!showDateDropdown) {
      setShow(false);
    }
  }, [showDateDropdown]);

  const selectedValue = hours.find((h) => h.value === time);

  return (
    <div
      id={wrapperId}
      className="flex w-full items-center gap-4 pt-2 md:mx-auto md:block"
    >
      <span className="mb-2 inline-block whitespace-nowrap text-center md:w-full">
        {type === 'start'
          ? t('search.date.startHour')
          : t('search.date.endHour')}
      </span>
      <BCustomSelect
        show={show}
        setShow={setShow}
        selector={`#date-dropdown-${from}`}
        wrapperClass="w-full"
        renderedSelectedValue={() =>
          selectedValue ? <HourItem hour={selectedValue} /> : null
        }
        parentSelector={`#search-${from}`}
        disabled={isDisabled}
      >
        {hours.map((hour) => (
          <DropdownClickableItem
            key={hour.value}
            isActive={hour.value === time}
            onClick={() => onClick(hour)}
          >
            <HourItem hour={hour} />
          </DropdownClickableItem>
        ))}
      </BCustomSelect>
    </div>
  );
};

export const HourItem = ({ hour }: { hour: SearchHourOption<number> }) => (
  <>
    {hour.prefix ? <div className="text-primary-500">{hour.prefix}</div> : null}
    <div className="flex flex-row items-center gap-2 text-base">
      {hour.icon} {hour.label}
    </div>
  </>
);

const setDateAndHour = (
  starter: Date,
  hour: SearchHourOption<number>,
  dateSetter: (val: Date) => void,
  hourSetter: (val: number) => void,
  startDate?: Date
) => {
  const dayjs = dateService.getDayjs();

  let d = dayjs(starter);
  d = d.hour(hour.value);
  if (startDate && dateService.isSameDate(starter, startDate)) {
    d = d.add(hour.dayOffset, 'day');
  }
  dateSetter(d.toDate());
  hourSetter(hour.value);
};

const DWSelector = () => {
  const { startTime, endTime, setStartTime, setEndTime } = useSearchState();

  const { locale } = useLocale();

  const selectedLocale = (
    {
      [Locale.TR]: tr,
      [Locale.EN]: enUS,
    } as Record<Locale, typeof tr>
  )[locale];

  const dateRangePickerProps = {
    className: 'overflow-hidden',
    color: 'var(--clr-primary-500)',
    showDateDisplay: false,
    locale: selectedLocale,
    showMonthAndYearPickers: false,
    rangeColors: ['var(--clr-primary-500)'],
    minDate: new Date(),
    // ...props,
  };

  const onChange = (val: RangeKeyDict) => {
    setStartTime(val.state.startDate || null);
    setEndTime(val.state.endDate || null);
  };

  return (
    <DateRangePicker
      fixedHeight
      ranges={[
        {
          startDate: startTime || new Date(),
          endDate: endTime || new Date(),
          key: 'state',
        },
      ]}
      onChange={onChange}
      {...dateRangePickerProps}
    />
  );
};
